<template>
  <v-container fluid class="mt-5">
    <v-row justify="center">
      <v-col cols="11">
        <v-expand-transition>
          <loader v-if="loading" />
          <v-row v-else>
            <create-sponsor @created="getSponsors" />
            <update-sponsor
              :id="selectedSponsor"
              @updated="getSponsors"
            />
            <delete-sponsor
              :id="selectedSponsor"
              @removed="getSponsors"
            />

            <v-col
              cols="12"
              class="d-flex justify-end"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    height="56px"
                    class="ml-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="createSponsor()"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Cadastrar Patrocinador</span>
              </v-tooltip>
            </v-col>

            <v-col
              lg="3"
              md="4"
              sm="6"
              cols="12"
              v-for="sponsor in sponsors"
              :key="sponsor._id"
            >
              <sponsor-card
                :sponsorData="sponsor"
                @updateSelected="updateSponsor"
                @removeSelected="removeSponsor"
                @deleted="getSponsors"
              />
            </v-col>

            <v-col
              v-if="sponsors.length === 0"
              cols="12"
            >
              <empty-data />
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
    Loader: () => import('@/components/LoadingCards'),
    EmptyData: () => import('@/components/EmptyData'),
    SponsorCard: () => import('./components/SponsorCard.vue'),
    CreateSponsor: () => import('./components/CreateSponsor.vue'),
    UpdateSponsor: () => import('./components/UpdateSponsor.vue'),
    DeleteSponsor: () => import('./components/DeleteSponsor.vue')
  },
  data () {
    return {
      loading: false,
      selectedSponsor: ''
    }
  },
  computed: {
    sponsors: {
      get () {
        return this.$store.state.sponsors
      },
      set (val) {
        this.$store.commit('SET_SPONSORS', val)
      }
    },
    createSponsorModal: {
      get () {
        return this.$store.state.createSponsorModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createSponsorModal',
          value: val
        })
      }
    },
    updateSponsorModal: {
      get () {
        return this.$store.state.updateSponsorModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateSponsorModal',
          value: val
        })
      }
    },
    deleteSponsorModal: {
      get () {
        return this.$store.state.deleteSponsorModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'deleteSponsorModal',
          value: val
        })
      }
    }
  },
  mounted () {
    this.getSponsors()
  },
  methods: {
    getSponsors () {
      this.loading = true

      this.$http.get('/sponsors')
        .then((res) => {
          const { data } = res.data
          this.sponsors = data
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => (this.loading = false))
    },
    createSponsor () {
      this.createSponsorModal = true
    },
    updateSponsor (id) {
      this.selectedSponsor = id
      this.updateSponsorModal = true
    },
    removeSponsor (id) {
      this.selectedSponsor = id
      this.deleteSponsorModal = true
    }
  }
}
</script>
